<template>
  <div class="text-sm md:text-lg">
    <span
      class="mx-1 font-bold"
      :class="{ 'opacity-50 cursor-pointer': locale !== 'th' }"
      @click="toggleLocale('th')"
      >TH</span
    >
    <span class="mx-1">|</span>
    <span
      class="mx-1 font-bold"
      :class="{ 'opacity-50 cursor-pointer': locale !== 'en' }"
      @click="toggleLocale('en')"
      >EN</span
    >
  </div>
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from '@/plugins/i18n'

const { locale } = useI18n()
const router = useRouter()
const route = useRoute()

const props = withDefaults(
  defineProps<{
    forceLocale?: 'th' | 'en'
  }>(),
  {},
)

const toggleLocale = (lang: 'th' | 'en'): void => {
  if (props.forceLocale) lang = 'en'

  locale.value = lang

  if (!router) return

  router.replace({ query: { ...route.query, lang } })
}
</script>
