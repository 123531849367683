<template>
  <div
    v-if="showHeader"
    class="relative bg-primary h-20 md:h-[6.5rem] 2xl:h-32 text-white font-medium flex justify-center items-center cursor-default"
  >
    <h1
      class="text-base md:text-[1.75rem] 2xl:text-4xl text-center md:leading-[42px] lg:leading-0"
      v-html="props.title"
    ></h1>
    <ToggleLocale
      v-if="props.showToggleLocale"
      class="absolute top-0 right-0 mx-4 my-2 md:mx-3 md:my-1.5"
    />
  </div>
  <slot class="overflow-y-auto" />
</template>
<script lang="ts" setup>
import ToggleLocale from '@/components/common/toggle-locale/ToggleLocale.vue'

const props = withDefaults(
  defineProps<{
    title?: string
    showToggleLocale?: boolean
    showHeader?: boolean
  }>(),
  {
    showToggleLocale: true,
    showHeader: true,
  },
)
</script>
